<template>
    <div>

        <!--Go to Profile modal -->
        <b-modal hide-footer no-close-on-backdrop scrollable title="Profile Submission" class="modal-dark" v-model="goToProfile">
            <b-button size="sm" variant="primary" @click="postAndGoToProfile(newProfile)">
                Submit and Go to Profile
            </b-button> &nbsp;
            <b-button size="sm" variant="primary" @click="postProfile(newProfile)">
                Create More Profiles
            </b-button>
        </b-modal>

        <!--Edit Columns Modal-->
        <b-modal hide-footer no-close-on-backdrop scrollable title="Edit Columns" class="modal-dark" v-model="editTable">
            <div v-for="column in allColumns">
                <b-checkbox v-if="column !== 'name'" v-model="columns" :value="column">
                    {{ tOptions.headings[column] }}
                </b-checkbox>
            </div>
        </b-modal>

        <!--Create Profile Modal-->
        <b-modal no-close-on-backdrop scrollable title="Create Profile" class="modal-dark" v-model="createProfile">
            <b-row>
                <b-col sm="6">
                    <b-form-group>
                        <label>First</label>
                        <InlineSearch v-model="newProfile.firstName" href-info="Profile Details"
                                      data-type="firstName" :arrow="false"
                                      :result-format="nameify" id-name="profileId"
                                      :data="this.profiles" :upper-case="true"/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>Last</label>
                        <InlineSearch v-model="newProfile.lastName" href-info="Profile Details"
                                      data-type="lastName" :arrow="false"
                                      :result-format="nameify" id-name="profileId"
                                      :data="this.profiles" :upper-case="true"/>
                    </b-form-group>
                </b-col>
                <b-col sm="10">
                    <b-form-group>
                        <label>Middle</label>
                        <InlineSearch v-model="newProfile.middleName" href-info="Profile Details"
                                      data-type="middleName" :arrow="false"
                                      :result-format="nameify" id-name="profileId"
                                      :data="this.profiles" :upper-case="true"/>
                    </b-form-group>
                </b-col>
                <b-col sm="2">
                    <b-form-group>
                        <label>Suffix</label>
                        <b-form-input v-model="newProfile.suffix" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>Birth Month</label>
                        <b-form-select :options="arrayify(1,12, true)"
                                       v-model="newProfile.birthMonth"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>Birth Day</label>
                        <b-form-select :options="arrayify(1,31, true)"
                                       v-model="newProfile.birthDay"/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label>Birth Year</label>
                        <b-form-select :options="arrayify(1900,(new Date()).getFullYear(), false)" v-model="newProfile.birthYear"/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>Sex</label>
                        <b-form-select :options="['MALE', 'FEMALE']" v-model="newProfile.sex"/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>Race</label>
                        <b-form-select :options="races" v-model="newProfile.race"/>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label>Weight (lb)</label>
                        <b-form-input @keypress="onlyNumber" maxLength="4" type="text" v-model.number="newProfile.weight"/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>Height (ft)</label>
                        <b-form-input @keypress="onlyNumber" maxLength="2" type="text" v-model.number="newProfile.heightFeet"/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>Height (in)</label>
                        <b-form-input @keypress="onlyNumber" maxLength="2" type="text" v-model.number="newProfile.heightInch"/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label class="labelForm">Social Security</label>
                        <b-form-input v-mask="'###-##-####'" placeholder="___-__-____" v-model="newProfile.socialSecurity" maxLength="11"/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label class="labelForm">Employer</label>
                        <b-form-input v-model="newProfile.employer" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="12" v-if="newProfile.ribbons">
                    <label class="labelForm">Ribbons</label>
                    <b-row>
                        <b-col sm="4" style="display: flex" v-for="(ribbon, index) in ribbons" :key="index + 'r'">
              <span v-if="newProfile.ribbons.length < 3 || (newProfile.ribbons.map(r => r.ribbon).includes(ribbon) && newProfile.ribbons.length === 3)" style="display: flex">
                  <b-form-checkbox v-model="newProfile.ribbons" :value="{ribbon: ribbon}"/>{{ ribbon }}
              </span>
                            <span v-else style="display: flex; color: grey">
                <b-form-checkbox v-model="newProfile.ribbons" :value="{ribbon: ribbon}" disabled/>
                <span style="color: grey;">{{ ribbon }}</span>
              </span>
                        </b-col>
                    </b-row>
                    <br>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label class="labelForm">MID</label>
                        <b-form-input v-model="newProfile.mid" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label class="labelForm">SID</label>
                        <b-form-input v-model="newProfile.sid" v-uppercase/>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group>
                        <label class="labelForm">FBI</label>
                        <b-form-input v-model="newProfile.fbi" v-uppercase/>
                    </b-form-group>
                </b-col>

                <!--Alias-->
                <b-col sm="12">
                    <label>Alias</label>&nbsp;
                    <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(newProfile.alias, fields.alias[0])"/>
                    <b-list-group v-for="(e,i) in newProfile.alias" :key="i" v-if="refresh">
                        <b-list-group-item>
                            <strong>Alias #{{ i + 1 }}</strong>
                            <fa-icon :icon="['fas', 'close']" style="float:right; cursor: pointer;" @click="splice(i, newProfile.alias, fields.alias[0])"
                               v-if="(newProfile.alias && newProfile.alias.length > 1) || (e.notes[0] !== '' && e.knownAlias !== '')"/>
                            <b-input-group class="input-group-sm" prepend="Name" style="margin-bottom: 1px">
                                <b-form-input v-model="newProfile.alias[i].knownAlias" v-uppercase/>
                            </b-input-group>
                            <b-input-group prepend="Notes" class="input-group-sm">
                                <b-form-input v-model="newProfile.alias[i].notes[0].text"/>
                            </b-input-group>
                        </b-list-group-item>
                    </b-list-group>
                    <br>
                </b-col>

                <!--Street Names-->
                <b-col sm="12">
                    <label>Street Names</label>&nbsp;
                    <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(newProfile.streetName, fields.streetName[0])"/>
                    <b-list-group :key="i" v-for="(e,i) in newProfile.streetName" v-if="refresh">
                        <b-list-group-item>
                            <strong>Street Name #{{ i + 1 }}</strong>
                            <fa-icon :icon="['fas', 'close']" @click="splice(i, newProfile.streetName, fields.streetName[0])" style="float:right; cursor: pointer;"
                               v-if="(newProfile.streetName && newProfile.streetName.length > 1) || (e.notes[0] !== '' && e.streetName !== '')"/>
                            <b-input-group class="input-group-sm" prepend="Name" style="margin-bottom: 1px">
                                <b-form-input v-model="newProfile.streetName[i].streetName" v-uppercase/>
                            </b-input-group>
                            <b-input-group class="input-group-sm" prepend="Notes">
                                <b-form-input v-model="newProfile.streetName[i].notes[0].text"/>
                            </b-input-group>
                        </b-list-group-item>
                    </b-list-group>
                    <br>
                </b-col>

                <!--IDs-->
                <b-col sm="12">
                    <label>IDs</label>&nbsp
                    <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(newProfile.identifications, fields.identifications[0])"/>
                    <b-list-group v-for="(e,i) in newProfile.identifications" :key="i" v-if="refresh">
                        <b-list-group-item v-if="!e.type && !e.status && !e.number&& !e.state">
                            <strong>ID #{{ i + 1 }}</strong>
                            <fa-icon :icon="['fas', 'close']" style="float: right; cursor: pointer;"
                                     @click="splice(i, newProfile.identifications, fields.identifications[0])"
                               v-if="e.length > 1"/>
                            <b-input-group class="input-group-sm" prepend="Type" style="margin-bottom: 1px">
                                <b-form-select :options="dropdownData.idTypes" v-model="e.type"/>
                            </b-input-group>
                            <b-input-group class="input-group-sm" prepend="Status" style="margin-bottom: 1px">
                                <b-form-select :options="dropdownData.idStatus" v-model="e.status"/>
                            </b-input-group>
                            <b-input-group class="input-group-sm" prepend="Number" style="margin-bottom: 1px">
                                <b-form-input v-model="e.number" v-uppercase/>
                            </b-input-group>
                            <b-input-group class="input-group-sm" prepend="State" style="margin-bottom: 1px">
                                <b-form-select :options="dropdownData.state" v-model="e.State"/>
                            </b-input-group>
                            <b-input-group prepend="Notes" class="input-group-sm">
                                <b-form-input v-model="e.notes[0].text"/>
                            </b-input-group>
                        </b-list-group-item>
                        <b-list-group-item v-else>
                            <strong>ID #{{ i + 1 }}</strong>
                            <fa-icon :icon="['fas', 'close']" style="float: right; cursor: pointer;"
                                     @click="splice(i, newProfile.identifications, fields.identifications[0])"/>
                            <b-input-group class="input-group-sm" prepend="Type" style="margin-bottom: 1px">
                                <b-form-select :options="dropdownData.idTypes"
                                               :class=" 'form-control is-' + (filledOut(e.type) ? 'valid':'invalid')"
                                               v-model="e.type"/>
                            </b-input-group>
                            <b-input-group class="input-group-sm" prepend="Status" style="margin-bottom: 1px">
                                <b-form-select :options="dropdownData.idStatus"
                                               :class=" 'form-control is-' + (filledOut(e.status) ? 'valid':'invalid')"
                                               v-model="e.status"/>
                            </b-input-group>
                            <b-input-group class="input-group-sm" prepend="Number" style="margin-bottom: 1px">
                                <b-form-input
                                    :class=" 'form-control is-' + (filledOut(e.number) ? 'valid':'invalid')"
                                    v-model="e.number" v-uppercase/>
                            </b-input-group>
                            <b-input-group class="input-group-sm" prepend="State" style="margin-bottom: 1px">
                                <b-form-select :options="dropdownData.state" v-model="e.State"/>
                            </b-input-group>
                            <b-input-group prepend="Notes" class="input-group-sm">
                                <b-form-input v-model="e.notes[0].text"/>
                            </b-input-group>
                        </b-list-group-item>
                    </b-list-group>
                    <br>
                </b-col>

                <!--Other Notes-->
                <b-col sm="12">
                    <b-form-group>
                        <label>Other Notes</label>&nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(newProfile.notes, fields.notes[0])"/>
                        <b-input-group v-for="(e,i) in newProfile.notes" :key="i" v-if="refresh">
                            <b-form-input v-model="newProfile.notes[i].text"/>
                            <b-input-group-append
                                v-if="(newProfile.notes && newProfile.notes.length > 1) || e.text">
                                <b-button variant="dark" @click="splice(i, newProfile.notes, fields.notes[0])">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>

            </b-row>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="createProfile=false">Cancel</b-button>
                <b-button size="sm" variant="primary" :disabled="disableCreateSubmit()" @click="goToProfile= true">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!--Profile Table-->
        <b-card style="width: 100%; height: 100%">
            <div slot="header">
        <div class="d-flex w-100 justify-content-between">
            <h5 style="margin-bottom: 0"><b>Profile Manager</b></h5>
            <div class="d-flex justify-content-end" style="cursor: pointer;">
                <fa-icon :icon="['fas', 'table-cells']" size="lg" style="margin-right: 7px" @click="editTable = true"/>
                <fa-icon :icon="['fas', 'user-plus']" size="lg" @click="openCreateProfile"/>
            </div>
        </div>
            </div>
            <v-client-table @row-click="rowClicked" ref="dTable" style="width: 100%" :columns="[...columns, 'info']" :data="profiles" :options="{...tOptions, sortable: columns, filterable: columns}" :theme="theme" class="dataTable">
                <div slot="birthday" slot-scope="props">
                    <span v-if="props.row.birthMonth">{{ props.row.birthMonth }}</span>
                    <span v-else>XX</span>
                    <span v-if="props.row.birthDay">/{{ props.row.birthDay }}</span>
                    <span v-else>/XX</span>
                    <span v-if="props.row.birthYear">/{{ props.row.birthYear }}</span>
                    <span v-else>/XXXX</span>
                    <span> {{profileAge(props.row)}} </span>
                </div>
                <div slot="height" slot-scope="props">
                    <div v-if="props.row.heightFeet !== null && props.row.heightInch !== null">
                        <span>{{ toFtIn(props.row.heightFeet, props.row.heightInch) }} ({{ toIn(props.row.heightFeet, props.row.heightInch) }}")</span>
                    </div>
                    <div v-else-if="props.row.heightFeet !== null">
                        <span>{{ props.row.heightFeet }}'0"({{ toIn(props.row.heightFeet, '0') }}")</span>
                    </div>
                    <div v-else-if="props.row.heightInch !== null">
                        <span>0'{{ props.row.heightInch }}"</span>
                    </div>
                    <div v-else>Unknown</div>
                </div>
                <div slot="weight" slot-scope="props">
                    <div v-if="props.row.weight !== null && props.row.weight !== 0">
                        <span>{{ props.row.weight }}</span>
                    </div>
                    <div v-else>Unknown</div>
                </div>
                <div slot="info" slot-scope="props">
                    <router-link :to="{ name: 'Profile Details', params: {id: props.row.profileId}}">
                        <span>{{ props.row.id }}</span>
                        <b-button style="float: right; color: #20a8d8; background-color: #2a2a2a; padding: 4px 15px;" size="sm" variant="dark">
                            <fa-icon :icon="['fas', 'info']" size="lg"/>
                        </b-button>
                    </router-link>
                </div>
                <div slot="h__info">
                    <span style="float: right; margin-bottom: 0">Info/Edit</span>
                </div>
            </v-client-table>
            <b-tooltip v-if="tooltipRender" ref="tooltip" :target="target" :title="tooltipTitle" triggers=""/>
        </b-card>

    </div>
</template>

<script>
import risc from '@/services/risc.js'
import Vue from 'vue'
import router from '@/router/router'
import {ClientTable} from 'vue-tables-2'
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import MaskedInput from 'vue-text-mask'
import VueMask from "v-mask";
import InlineSearch from "../InlineSearch";
/*import Axios from "axios";
import {gMapApi} from "vue2-google-maps";*/

Vue.use(ClientTable)
Vue.use(VueMask);

export default {

    components: {
        datePicker,
        MaskedInput,
        InlineSearch,
    },
    computed: {},
    data: () => {
        return {
            ribbons: ['IN CUSTODY', 'WANTED', 'OUT OF TOWN', 'DECEASED', 'PAROLE/PROBATION'],
            races: [
                'AMERICAN INDIAN',
                'ASIAN',
                'BLACK',
                'HISPANIC',
                'NATIVE HAWAIIAN/OTHER PACIFIC ISLANDER',
                'WHITE',
                'BIRACIAL',
                'OTHER'
            ],
            address: {
                states: [
                    'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
                    'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
                    'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
                    'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
                    'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
                ],
            },
            tooltipTitle: "",
            tooltipRender: true,
            target: '',
            date: new Date(),
            options: {
                format: 'MM/DD/YYYY',
                useCurrent: true,
            },
            refresh: true,
            profiles: [],
            newProfile: {},
            fields: {
                firstName: '',
                middleName: '',
                lastName: '',
                suffix: '',
                ribbons: [],
                alias: [
                    {knownAlias: '', notes: [{text: ''}], description: ''}
                ],
                streetName: [
                    {streetName: '', notes: [{text: ''}], description: ''}
                ],
                phone: [
                    {active: true, number: "", notes: ''}
                ],
                birthMonth: undefined,
                birthDay: undefined,
                birthYear: undefined,
                heightFeet: 0,
                heightInch: 0,
                weight: 0,
                race: undefined,
                socialSecurity: '',
                mid: '',
                sid: '',
                fbi: '',
                socialMedia: [
                    {site: "", userName: "", link: "", notes: [{text: ''}]}
                ],
                identifications: [
                    {type: '', number: '', status: '', state: '', notes: [{text: ''}]}
                ],
                markings: [
                    {type: '', description: ''}
                ],
                notes: [
                    {text: ''}
                ],
            },
            //Modals
            createProfile: false,
            goToProfile: false,
            deleteProfileVerification: false,
            //Data Table
            editTable: false,
            allColumns: ['name', 'birthday', 'height', 'weight', 'race', 'sex', 'employer', 'mid', 'sid', 'fbi', 'socialSecurity'],
            columns: ['name', 'birthday', 'height', 'weight', 'race', 'sex'],
            tOptions: {
                orderBy: {column: 'name', ascending: true},
                headings: {
                    name: 'Name(First, Last  Middle)',
                    streetName: 'Street Name',
                    birthday: 'DOB',
                    height: 'Height',
                    weight: 'Weight(lbs)',
                    race: 'Race',
                    sex: 'Sex',
                    alias: 'Alias',
                    employer: 'Employer',
                    mid: 'MID',
                    sid: 'SID',
                    fbi: 'FBI',
                    socialSecurity: 'SSN',
                    info: 'Info/Edit'
                },
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
                customSorting: {
                    birthday: function (ascending) {
                        return function (a, b) {
                            let aDate = new Date(a.birthYear, a.birthMonth-1, a.birthDay);
                            let bDate = new Date(b.birthYear, b.birthMonth-1, b.birthDay);
                            if (aDate < bDate) {
                                return ascending === true ? -1 : 1;
                            } else if (aDate > bDate) {
                                return ascending === true ? 1 : -1;
                            }
                            return 0;
                        }
                    },
                    height: function (ascending) {
                        return function (a, b) {
                            let aHeight = (a.heightInch + a.heightFeet * 12);
                            let bHeight = (b.heightInch + b.heightFeet * 12);
                            if (aHeight < bHeight) {
                                return ascending === true ? -1 : 1;
                            } else if (aHeight > bHeight) {
                                return ascending === true ? 1 : -1;
                            }
                            return 0;
                        }
                    }
                }
            },
            useVuex: false,
            theme: 'bootstrap4',
            template: 'default',
            dropdownData: {
                idTypes: ['DRIVERS LICENSE', 'STATE ID', 'FOID CARD', 'CONCEALED CARRY', 'OTHER'],
                idStatus: ['VALID', 'SUSPENDED', 'REVOKED', 'ACTIVE', 'N/A', 'OTHER'],
                state: ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
                    'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
                    'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
                    'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
                    'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY', "Mexico", "Canada", "Other"],
            }
        }
    },

    created() {
        this.getProfile()
    },

    methods: {
        nameify(profile) {
            if (profile.firstName && profile.lastName && profile.middleName) {
                return (profile.lastName + ', ' + profile.firstName + ' ' + profile.middleName.slice(0, 1))
            } else if (profile.firstName && profile.lastName) {
                return (profile.lastName + ', ' + profile.firstName)
            } else if (profile.firstName && profile.middleName) {
                return ('Unknown, ' + profile.firstName + ' ' + profile.middleName.slice(0, 1))
            } else if (profile.lastName && profile.middleName) {
                return (profile.lastName + ', Unknown ' + profile.middleName.slice(0, 1))
            } else if (profile.firstName) {
                return ('Unknown, ' + profile.firstName)
            } else if (profile.lastName) {
                return (profile.lastName + ', Unknown ')
            } else if (profile.middleName) {
                return ('Unknown, Unknown ' + profile.middleName.slice(0, 1))
            }
            return '';
        },
        aliasify(arr) {
            let result = ""
            if (Array.isArray(arr)) {
                arr.forEach((a, i) => {
                    result += (a.knownAlias.length > 0 ? (i + 1 < arr.length ? a.knownAlias : a.knownAlias + ", ") : "")
                })
            }
            return result;
        },
        streetNameify(arr) {
            let result = ""
            if (Array.isArray(arr)) {
                arr.forEach((a, i) => {
                    result += (a.streetName.length > 0 ? (i + 1 === arr.length ? a.streetName : a.streetName + ", ") : "")
                })
            }
            return result;
        },
        arrayify(start, end, addToEnd) {
            let result = [];
            for (let i = start; i < end + 1; i++) {
                if (addToEnd) {
                    if (i > 9) {
                        result.push({text: i, value: i})
                    } else if (i >= 0) {
                        result.push({text: "0" + i, value: i})
                    }
                } else {
                    if (i > 9) {
                        result.unshift({text: i, value: i})
                    } else if (i >= 0) {
                        result.unshift({text: "0" + i, value: i})
                    }
                }
            }
            return (result);
        },
        rowClicked(item) {
            //ctrl click to make tooltip appear with id
            if (item.event.ctrlKey) {
                this.tooltipRender = false;
                this.tooltipTitle = item.row._id;
                item.event.target.id = item.row._id;
                this.target = item.row._id;
                //restart tooltip so it can map to its new position
                Vue.nextTick().then(() => {
                    this.tooltipRender = true;
                    Vue.nextTick().then(() => {
                        this.$refs.tooltip.$emit('open')
                        setTimeout(function () {
                            //make the tooltip disappear automatically
                            this.$refs.tooltip.$emit('close');
                        }.bind(this), 4000)
                    });
                });
            }
        },
        splice(index, field, item) {
            field.splice(index, 1);
            if (item && field.length === 0) {
                this.push(field, item)
            }
            this.refresh = false;
            this.refresh = true;
        },
        push(field, item) {
            field.push(JSON.parse(JSON.stringify(item)));
            this.refresh = false;
            this.refresh = true;
        },
        active(e) {
            e.active = !e.active;
            this.refresh = false;
            this.refresh = true;
        },
        getProfile() {
            risc.getProfiles().then(response => {
                response.data.forEach(profile => {
                    if (profile.firstName && profile.lastName && profile.middleName) {
                        profile.name = profile.lastName + ", " + profile.firstName + " " + profile.middleName.slice(0, 1)
                    } else if (profile.firstName && profile.lastName) {
                        profile.name = profile.lastName + ", " + profile.firstName
                    } else if (profile.firstName && profile.middleName) {
                        profile.name = "Unknown, " + profile.firstName + " " + profile.middleName.slice(0, 1);
                    } else if (profile.lastName && profile.middleName) {
                        profile.name = profile.lastName + ", Unknown" + profile.middleName.slice(0, 1);
                    } else if (profile.firstName) {
                        profile.name = "Unknown, " + profile.firstName;
                    } else if (profile.lastName) {
                        profile.name = profile.lastName + ", Unknown"
                    }
                });
                this.profiles = response.data;
            })
        },
        postProfile() {
            this.createProfile = false;
            Object.keys(this.fields).forEach(field => {
                if (JSON.stringify(this.newProfile[field]) === JSON.stringify(this.fields[field]) || this.newProfile[field] === undefined) {
                    delete this.newProfile[field];
                }
            })
            if (this.newProfile.alias) {
                for (let i = 0; i < this.newProfile.alias.length; i++) {
                    if (this.newProfile.alias[i].knownAlias === '' || this.newProfile.alias[i].knownAlias === null || this.newProfile.alias[i].knownAlias === undefined) {
                        this.newProfile.alias.splice(i, 1);
                        i -= 1;
                    }
                }
            }
            if (this.newProfile.streetNames) {
                for (let i = 0; i < this.newProfile.streetNames.length; i++) {
                    if (this.newProfile.streetNames[i].streetName === '' || this.newProfile.streetNames[i].streetName === null || this.newProfile.streetNames[i].streetName === undefined) {
                        this.newProfile.streetNames.splice(i, 1);
                        i -= 1;
                    }
                }
            }
            if (this.newProfile.identifications) {
                for (let i = 0; i < this.newProfile.identifications.length; i++) {
                    if (this.newProfile.identifications[i].type === '' || this.newProfile.identifications[i].type === null || this.newProfile.identifications[i].type === undefined) {
                        this.newProfile.identifications.splice(i, 1);
                        i -= 1;
                    }
                }
            }
            risc.postProfiles(this.newProfile).then(() => {
                this.getProfile();
            })
            this.goToProfile = false;
        },
        postAndGoToProfile() {
            this.createProfile = false;
            Object.keys(this.fields).forEach(field => {
                if (JSON.stringify(this.newProfile[field]) === JSON.stringify(this.fields[field]) || this.newProfile[field] === undefined) {
                    delete this.newProfile[field];
                }
            })
            if (this.newProfile.alias) {
                for (let i = 0; i < this.newProfile.alias.length; i++) {
                    if (this.newProfile.alias[i].knownAlias === '' || this.newProfile.alias[i].knownAlias === null || this.newProfile.alias[i].knownAlias === undefined) {
                        this.newProfile.alias.splice(i, 1);
                        i -= 1;
                    }
                }
            }
            if (this.newProfile.streetNames) {
                for (let i = 0; i < this.newProfile.streetNames.length; i++) {
                    if (this.newProfile.streetNames[i].streetName === '' || this.newProfile.streetNames[i].streetName === null || this.newProfile.streetNames[i].streetName === undefined) {
                        this.newProfile.streetNames.splice(i, 1);
                        i -= 1;
                    }
                }
            }
            if (this.newProfile.identifications) {
                for (let i = 0; i < this.newProfile.identifications.length; i++) {
                    if (this.newProfile.identifications[i].type === '' || this.newProfile.identifications[i].type === null || this.newProfile.identifications[i].type === undefined) {
                        this.newProfile.identifications.splice(i, 1);
                        i -= 1;
                    }
                }
            }
            risc.postProfiles(this.newProfile).then(response => {
                this.goProfiles = response.data
                router.push({path: `/risc/profile/${this.goProfiles.profileId}`})
            })
        },
        toIn(ft, inch) {
            return (inch + parseInt(ft) * 12);
        },
        toFtIn(ft, inch) {
            return ((Math.floor(this.toIn(ft, inch) / 12)) + "'" + (this.toIn(ft, inch) % 12) + "\"")
        },
        openCreateProfile() {
            this.newProfile = {};
            this.newProfile = Object.assign({}, JSON.parse(JSON.stringify(this.fields)))
            this.createProfile = true;
        },
        filledOut(i) {
            return !(i === null || i === undefined || i === '' || i === 0);

        },
        disableCreateSubmit() {
            if (this.newProfile.identifications) {
                let result = true;
                let allIdsGood = true;
                Object.keys(this.fields).forEach(field => {
                    if (typeof this.newProfile[field] === 'object') {
                        this.newProfile[field].forEach(e => {
                            if (JSON.stringify(e) !== JSON.stringify(this.fields[field][0]))
                                result = false;
                        })
                    } else if (this.filledOut(this.newProfile[field])) {
                        result = false;
                    }
                })
                /*const allIdsValid = this.newProfile.identifications.forEach(id => {
                    const typeThere = id.type !== '' && id.type !== null && id.type !== undefined;
                    const statusThere = id.status !== '' && id.status !== null && id.status !== undefined;
                    const numberThere = id.number !== '' && id.number !== null && id.number !== undefined;
                    if (!typeThere && !statusThere && !numberThere) {
                        // return id.type && id.status && id.number;
                    } else if (typeThere && statusThere && numberThere) {
                        // return !typeThere && !statusThere && !numberThere;
                    } else {
                        allIdsGood = false;
                    }
                });*/
                return !(!result && allIdsGood);
            }
        },
        tocm(ft, inch) {
            let cmeters = 0;
            let newInch = 0;
            ft = parseFloat(ft);
            inch = parseFloat(inch);
            newInch = inch + (ft * 12);
            cmeters = newInch * 2.54;
            cmeters = String(cmeters);
            cmeters = cmeters.slice(0, cmeters.indexOf(".") + 3)
            return (cmeters)
        },
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        profileAge(age) {
            if(age.birthYear !== 0) {
                let profileBirthday = new Date(age.birthYear, age.birthMonth - 1, age.birthDay);
                if (((Date.now() - profileBirthday) / 31557600000) > 0 && ((Date.now() - profileBirthday) / 31557600000) < 120) {
                    return '(' + ~~((Date.now() - profileBirthday) / (31557600000)).toString() + 'yrs)';
                }
            }
        }
    },

}

/*Makes B-Form-Input All Caps*/
Vue.directive('uppercase', {
    update(el) {
        el.value = el.value.toUpperCase()
    },
})

</script>
